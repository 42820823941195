<template>
  <el-drawer title="详情"
             v-model="modalShow"
             size="40%"
             append-to-body
             destroy-on-close
             :before-close="handleClose"
             custom-class="modal_detail">
    <div class="drawer_padding">
      <div class="drawer_box">
        <div class="reject-info"
             v-if="+detailData.cert.cert_status === 2">
          审核未通过：{{detailData.cert.cert_reason}}
        </div>
        <div class="detail_header">
          <span>
            <i class="yuanquan"></i>
            个人认证信息</span>
          <span :class="[showStatus[detailData.cert.cert_status].class,'status']">
            <i :class="showStatus[detailData.cert.cert_status].icon"></i>
            {{showStatus[detailData.cert.cert_status].title}}
          </span>
        </div>
        <div class="detail_center">
          <div class="title_item">
            <span class="title">姓名：</span>
            <span class="content">{{detailData.certInfo.card_info.name}}</span>
          </div>
          <div class="title_item">
            <span class="title">身份证号码：</span>
            <span class="content">{{detailData.certInfo.card_info.number}}</span>
          </div>
          <div class="title_item">
            <span class="title">有效期：</span>
            <span class="content">{{$moment(detailData.certInfo.card_info.expire * 1000).format('YYYY-MM-DD')}}</span>
          </div>
          <div class="title_item">
            <span class="title">身份证照片：</span>
            <div class="img_box">
              <el-image class="img"
                        :src="detailData.certInfo.card_info.a_img"
                        :preview-src-list="[detailData.certInfo.card_info.a_img]">
              </el-image>
              <span class="look_img">点击图片预览</span>
            </div>
          </div>
          <div class="title_item">
            <span class="title"></span>
            <div class="img_box">
              <el-image class="img"
                        :src="detailData.certInfo.card_info.b_img"
                        :preview-src-list="[detailData.certInfo.card_info.b_img]">
              </el-image>
              <span class="look_img">点击图片预览</span>
            </div>
          </div>
        </div>
        <div class="detail_btn"
             v-if="tabcurrent === '0'">
          <el-button class='off_btn'
                     @click="onGo('2',detailData.cert.id)">驳回</el-button>
          <el-button class="on_btn"
                     @click="onGo('1',detailData.cert.id)">通过</el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, toRefs, reactive, getCurrentInstance, watch } from 'vue'

export default defineComponent({
  emits: ['closeDetail'],
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => { }
    },
    tabcurrent: {
      type: String,
      default: '0'
    }
  },
  setup (props, context) {
    const data = reactive({ // 定义变量
      modalShow: false,
      showStatus: {
        0: {
          title: '待审核',
          class: 'review',
          icon: 'el-icon-remove-outline'
        },
        1: {
          title: '审核通过',
          class: 'green',
          icon: 'el-icon-circle-check'
        },
        2: {
          title: '审核未通过',
          class: 'red',
          icon: 'el-icon-circle-close'
        }
      }
    })
    const { proxy } = getCurrentInstance()
    const handleClose = () => {
      context.emit('closeDetail')
      data.modalShow = false
    }
    const onGo = async (status, id) => {
      if (status === '1') {
        try {
          await proxy.$api.certexamine(
            {
              cert_id: id,
              cert_status: status,
              cert_reason: ''
            })
          handleClose()
          proxy.$notify({
            title: '系统通知',
            message: '操作成功',
            type: 'success'
          })
        } catch (err) {
          proxy.$notify({
            title: '系统通知',
            message: err || '系统错误',
            type: 'error'
          })
        }
      } else {
        try {
          proxy.$prompt('请输入驳回原因', '驳回原因', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /\S/,
            inputErrorMessage: '输入内容不能为空'
          }).then(async ({ value }) => {
            await proxy.$api.certexamine(
              {
                cert_id: id,
                cert_status: status,
                cert_reason: value
              })
            proxy.$notify({
              title: '系统通知',
              message: '驳回成功',
              type: 'success'
            })
            handleClose()
          }).catch(() => {
          })
        } catch (err) {
          proxy.$notify({
            title: '系统通知',
            message: err || '系统错误',
            type: 'error'
          })
        }
      }
    }
    watch(props, (val) => {
      data.modalShow = val.showModal
    })
    return {
      ...toRefs(data),
      handleClose,
      onGo
    }
  }
})
</script>
<style lang="scss" scoped>
.modal_detail {
  background: #fff;
}
.reject-info {
  padding: 5px 10px;
  background-color: #fffcf0;
  color: #f09e00;
  font-size: 12px;
  margin-bottom: 20px;
  border: 1px solid #ffeaa3;
}
.detail_header {
  color: #333;
  font-size: 14px;
  position: relative;
  .yuanquan {
    display: inline-block;
    border: 4px solid #f54a4a;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: relative;
    top: 2px;
  }
  .status {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 5px 10px;
    border-radius: 4px;
  }
}
.detail_center {
  margin-top: 30px;
  font-size: 14px;
  padding-bottom: 32px;
  margin-bottom: 26px;
  border-bottom: 1px solid #ededed;
  .title_item {
    margin: 14px;
    display: flex;
    .title {
      display: inline-block;
      width: 100px;
      color: #333;
    }
    .content {
      color: #666;
      .img {
        width: 230px;
        height: 144px;
      }
    }
    .img_box {
      display: flex;
      flex-direction: column;
      position: relative;
      .img {
        margin-top: 10px;
        width: 230px;
        height: 144px;
      }
      .look_img {
        position: absolute;
        display: none;
        width: 80px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #000;
        opacity: 0.5;
        color: #fff;
        border-radius: 2px;
        top: 10px;
        left: 0;
        font-size: 12px;
      }
    }
    .img_box:hover {
      .look_img {
        display: inline-block;
      }
    }
  }
}
.detail_btn {
  padding-left: 15px;
  .off_btn {
    background: #ff4d4d;
  }
  .on_btn {
    background: #1890ff;
  }
}
.review {
  color: #ff7e2a;
  background: #ffefe5;
}
.green {
  color: #01d356;
  background: #f3fff8;
}
.red {
  color: #f12200;
  background: #fff6f5;
}
.detail_btn::v-deep(.el-button) {
  width: 100px;
  height: 32px;
  color: #fff;
}
.img_box::v-deep(.el-image__inner) {
  object-fit: cover;
}
</style>
